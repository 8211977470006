class ZIDXAccountLandingPageVersions implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountLandingPageVersionsContainer";
    }
    getMatchURL(){
        return "/account/landing-pages/versions";
    }
    render(){
        // console.log("landing page versions");
    }
}